import { Box, Typography } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'
import { object, string } from 'yup'

export const actionPlanSchema = object({
  actionPlan: string()
    .required('This field is required')
    .min(15, 'Should be atleast 15 characters'),
})

export const ActionPlan = () => {
  return (
    <>
      <Box marginTop={5}>
        <Typography variant="h6" align="center">
          A2. Action plan untuk target dan sales anda
        </Typography>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <Field
          fullWidth
          name="actionPlan"
          component={TextField}
          label="Action Plan"
          multiline
          InputProps={{ variant: 'filled' }}
        />
      </Box>
    </>
  )
}
