import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const E2Schema = object({
  sepatuWanita: string().required(),
  bauBadanMulut: string().required(),
  doaPagi: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData(
    'Sepatu wanita min 3Cm hitam polos ?',
    <RateRadio name="sepatuWanita" />
  ),
  createData('Bau badan / mulut', <RateRadio name="bauBadanMulut" />),
  createData('Melakukan doa pagi', <RateRadio name="doaPagi" />),
]

export const E2 = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          E2. Penampilan dan Kebersihan Staff
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
