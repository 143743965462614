import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const Success = () => {
  return (
    <div>
      <Card>
        <CardContent>
          <Box textAlign="center">
            <Typography variant="h3">
              <p>Visit created successfully</p>
            </Typography>
            <Link to="/">Click to create new one </Link>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}
