import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const AdministrasiSchema = object({
  kesediaanStrukBonShopbag: string().required(),
  logBook: string().required(),
  slipPettycash: string().required(),
  stockVsSystem: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData(
    'Kesediaan Struk EDC, Bon Manual, Shopping bag',
    <RateRadio name="kesediaanStrukBonShopbag" />
  ),
  createData('Keaktifan penulisan Log book', <RateRadio name="logBook" />),
  createData(
    'Kerapihan file slip setoran bank dan petty cash Balance',
    <RateRadio name="slipPettycash" />
  ),
  createData(
    'Stock sesuai dengan System ?',
    <RateRadio name="stockVsSystem" />
  ),
]

export const Administrasi = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          C2. Administrasi
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
