// Component
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  MobileStepper,
  Typography,
} from '@material-ui/core'
import Axios from 'axios'
import { Form, Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { BASE_URL } from '../api/api'

// Own Component
import {
  ActionPlan,
  actionPlanSchema,
  ActionPlanVM,
  actionPlanVMSchema,
  Administrasi,
  AdministrasiSchema,
  butikSchema,
  ButikVisit,
  Catatan,
  CatatanSchema,
  Comment,
  CommentSchema,
  D1,
  D1Schema,
  D2,
  D2Schema,
  DeadlineActionPlan,
  deadlineActionPlaneSchema,
  Disiplin,
  DisiplinAdministrasiSchema,
  DisiplinDanAdministrasi,
  DisiplinSchema,
  E1,
  E1Schema,
  E2,
  E2Schema,
  E3,
  E3Schema,
  Email,
  emailSchema,
  F1,
  F1Schema,
  F2,
  F2Schema,
  PersonalData,
  ProductDisplay,
  ProductDisplaySchema,
  SalesAndTargetBoutique,
  SalesAndTargetBoutiqueSchema,
  Satisfied,
  SpgSchema,
  SpgTeam,
  visitorSchema,
} from '../components'
import useCreateVisit from '../hooks/Visit/useCreateVisit'

const initialData = {
  actionPlan: '',
  actionPlanGrooming: '',
  actionPlanVm: '',
  actionTargetLastVisit: '',
  bacaEmail: '',
  bauBadanMulut: '',
  catatan: '',
  deadlineActionPlan: new Date(),
  disiplinAbsen: '',
  disiplinAdministrasi: '',
  displayVm: '',
  doaPagi: '',
  email: '',
  firstName: '',
  gembokKasir: '',
  greetings: '',
  imageWithSa: '',
  image_1: '',
  keadaanToko: '',
  kebersihanDisplayLuarDalam: '',
  kebersihanDisplayShowcase: '',
  kebersihanFloorAndGudang: '',
  kebersihanShowcase: '',
  kebutuhanCust: '',
  kesediaanStrukBonShopbag: '',
  keunggulanProduk: '',
  komentar: '',
  kondisiSeragam: '',
  labelPricetag: '',
  lampu: '',
  lastGreetings: '',
  lastName: '',
  logBook: '',
  makeupIkatMinyak: '',
  menawarkanMember: '',
  nametagScraft: '',
  overallReview: '',
  perawatanProduk: '',
  productKnowledge: '',
  promoHighlightProduk: '',
  salesVsTarget: '',
  salesWeeklyMonthly: '',
  sepatuWanita: '',
  signedArea: '',
  slipPettycash: '',
  staffSeragam: '',
  standardVm: '',
  stockVsSystem: '',
  teamBtq: '',
  tncAfterSales: '',
  upCrossSelling: '',
  visitedBtq: '',
  windowDisplayGudangPlan: '',
}

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    background: '#ffffff',
  },
})

export const Home = () => {
  const [createVisit] = useCreateVisit()
  const [image, setImage] = React.useState(null)
  const [visitorImage, setVisitorImage] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const uploadImage = async (e) => {
    try {
      const files = e.target.files
      const data = new FormData()
      data.append('image_1', files[0])
      setLoading(true)
      const res = await Axios.post(`${BASE_URL}/image`, data)
      const file = await res.data
      setImage(file.data.imageUrl)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert(error.response.data)
    }
  }

  const uploadImage2 = async (e) => {
    try {
      const filesSelfie = e.target.files
      const data = new FormData()
      data.append('image_1', filesSelfie[0])
      setLoading(true)
      const res = await Axios.post(`${BASE_URL}/image`, data)
      const fileSelfie = await res.data
      setVisitorImage(fileSelfie.data.imageUrl)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert(error.response.data)
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <FormikStepper
            initialValues={{ ...initialData }}
            onSubmit={async (values) => {
              await createVisit({
                ...values,
                deadlineActionPlan: moment(values.deadlineActionPlan).format(
                  'YYYY-MM-DD'
                ),
                image_1: image,
                selfieVisitor: visitorImage,
              })
            }}
          >
            {/* Personal Data */}
            <FormikStep label="Data Visitors" validationSchema={visitorSchema}>
              <PersonalData />
            </FormikStep>
            {/* Personal Data */}

            {/* Email */}
            <FormikStep label="Email" validationSchema={emailSchema}>
              <Email />
            </FormikStep>
            {/* Email */}

            {/* Butik */}
            <FormikStep label="Butik" validationSchema={butikSchema}>
              <ButikVisit />
            </FormikStep>
            {/* Butik */}

            {/* Selfie */}
            <FormikStep label="selfie">
              <>
                <Box marginTop={5} marginBottom={2}>
                  <h1>Selfie depan Boutique (File format JPG, JPEG, PNG)</h1>
                  <input
                    type="file"
                    name="selfieVisitor"
                    placeholder="Upload an Image"
                    required
                    onChange={uploadImage2}
                  />
                  <br />
                  {loading ? (
                    <h3>Uploading file... {<CircularProgress />}</h3>
                  ) : visitorImage ? (
                    <>
                      <Box textAlign="center">
                        <img
                          src={`${BASE_URL}${visitorImage}`}
                          width="250"
                          height="250px"
                          alt="Boutique"
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </>
            </FormikStep>

            {/* Selfie */}

            {/* Team SPG */}
            <FormikStep label="SPG" validationSchema={SpgSchema}>
              <SpgTeam />
            </FormikStep>
            {/* Team SPG */}

            {/* A. Sales And Target */}

            {/* Opening */}
            <FormikStep label="A. Sales And Target">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  A. SALES & TARGETS
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    3 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>
            {/* Opening */}

            {/* A1. Sales & Target Boutique */}
            <FormikStep
              label="A1"
              validationSchema={SalesAndTargetBoutiqueSchema}
            >
              <SalesAndTargetBoutique />
            </FormikStep>

            {/* A1. Sales & Target Boutique */}

            {/* A2. Action plan */}
            <FormikStep label="Action Plan" validationSchema={actionPlanSchema}>
              <ActionPlan />
            </FormikStep>
            {/* A2. Action plan */}

            {/* A3. Batas Waktu Action Plan */}
            <FormikStep
              label="Deadline"
              validationSchema={deadlineActionPlaneSchema}
            >
              <DeadlineActionPlan />
            </FormikStep>

            {/* A3. Batas Waktu Action Plan */}

            {/* A. Sales And Target */}

            {/* B. Visual Merchandising */}

            {/* Opening */}
            <FormikStep label="B. Sales And Target">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  B. Visual Merchandising
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    2 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>
            {/* Opening */}

            {/* B1 */}
            <FormikStep label="B1" validationSchema={ProductDisplaySchema}>
              <ProductDisplay />
            </FormikStep>
            {/* B1 */}

            {/* B2 */}
            <FormikStep label="B2" validationSchema={actionPlanVMSchema}>
              <ActionPlanVM />
            </FormikStep>

            {/* B2 */}

            {/* B. Visual Merchandising */}

            {/* C. Displin & Administrasi */}

            {/* Opening */}
            <FormikStep label="COPENING">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  C. Disiplin & Administrasi
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    3 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>
            {/* Opening */}

            {/* C1. Disiplin */}
            <FormikStep label="disiplin" validationSchema={DisiplinSchema}>
              <Disiplin />
            </FormikStep>
            {/* C1. Disiplin */}

            {/* C2. Administrasi */}
            <FormikStep
              label="administrasi"
              validationSchema={AdministrasiSchema}
            >
              <Administrasi />
            </FormikStep>
            {/* C2. Administrasi */}

            {/* C3. Disiplin dan Administrasi */}
            <FormikStep
              label="c3"
              validationSchema={DisiplinAdministrasiSchema}
            >
              <DisiplinDanAdministrasi />
            </FormikStep>
            {/* C3. Disiplin dan Administrasi */}

            {/* C. Displin & Administrasi */}

            {/* D. Window, Display & Stock Room */}
            {/* Opening */}
            <FormikStep label="DOPENING">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  D. Window, Display & Stock Room
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    2 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>
            {/* Opening */}

            {/* D1. Kelengkapan dan kebersihan */}
            <FormikStep label="D1" validationSchema={D1Schema}>
              <D1 />
            </FormikStep>
            {/* D1. Kelengkapan dan kebersihan */}

            {/* D2. Action Plan Window, Display, Gudang */}
            <FormikStep label="D2" validationSchema={D2Schema}>
              <D2 />
            </FormikStep>
            {/* D2. Action Plan Window, Display, Gudang */}

            {/* D. Window, Display & Stock Room */}

            {/* E. GROOMING */}

            {/* Opening */}
            <FormikStep label="EOPENING">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  E. GROOMING (Kerapihan dan kebersihan Staff)
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    3 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>
            {/* Opening */}

            {/* E1. Kelengkapan, Kerapihan Staff */}
            <FormikStep label="E1" validationSchema={E1Schema}>
              <E1 />
            </FormikStep>
            {/* E1. Kelengkapan, Kerapihan Staff */}

            {/* E2. Penampilan dan Kebersihan Staff */}
            <FormikStep label="E2" validationSchema={E2Schema}>
              <E2 />
            </FormikStep>
            {/* E2. Penampilan dan Kebersihan Staff */}

            {/* E3. Action Plan Grooming */}
            <FormikStep label="E3" validationSchema={E3Schema}>
              <E3 />
            </FormikStep>
            {/* E3. Action Plan Grooming */}

            {/* E. GROOMING */}

            {/* F. SELLING PROCESS */}

            {/* Opening */}
            <FormikStep label="FOPENING">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  F. SELLING PROCESS
                </Typography>
                <Typography variant="h6" align="center">
                  Monitor standard service dari greeting sampai terjadi
                  penjualan.
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    2 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>
            {/* Opening */}

            {/* F1. Team Butik Melakukan / Menjelaskan point dibawah pada customer */}
            <FormikStep label="F1" validationSchema={F1Schema}>
              <F1 />
            </FormikStep>
            {/* F1. Team Butik Melakukan / Menjelaskan point dibawah pada customer */}

            {/* F2. Team Butik Melakukan / Menjelaskan point dibawah pada customer */}

            {/* F2. Team Butik Melakukan / Menjelaskan point dibawah pada customer */}
            <FormikStep label="F2" validationSchema={F2Schema}>
              <F2 />
            </FormikStep>
            {/* F. SELLING PROCESS */}

            {/* Penilaian Menyeluruh */}
            <FormikStep label="PENILAIAN">
              <Box marginX={'auto'} marginTop={5}>
                <Typography variant="h6" align="center">
                  Penilaian Menyeluruh dari Operation
                </Typography>
                <Box marginTop={5}>
                  <Typography variant="subtitle1" align="center">
                    5 Questions
                  </Typography>
                </Box>
              </Box>
            </FormikStep>

            {/* Overall */}
            <FormikStep label="overall">
              <Satisfied />
            </FormikStep>
            {/* Overall */}

            {/* Catatan */}
            <FormikStep label="catatan" validationSchema={CatatanSchema}>
              <Catatan />
            </FormikStep>
            {/* Catatan */}

            {/* Comment */}
            <FormikStep label="comment" validationSchema={CommentSchema}>
              <Comment />
            </FormikStep>
            {/* Comment */}

            {/* Comment */}
            <FormikStep label="photowithbtq">
              <>
                <Box marginTop={5} marginBottom={2}>
                  <h1>Foto dengan SA (File format JPG, JPEG, PNG)</h1>
                  <input
                    type="file"
                    name="image_1"
                    placeholder="Upload an Image"
                    required
                    onChange={uploadImage}
                  />
                  <br />
                  {loading ? (
                    <h3>Uploading file... {<CircularProgress />}</h3>
                  ) : image ? (
                    <>
                      <Box textAlign="center">
                        <img
                          src={`${BASE_URL}${image}`}
                          width="250"
                          height="250px"
                          alt="Boutique"
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </>
            </FormikStep>
            {/* Comment */}

            {/* Penilaian Menyeluruh */}
          </FormikStepper>
        </CardContent>
      </Card>
    </>
  )
}

export function FormikStep({ children }) {
  return <>{children}</>
}

export function FormikStepper({ children, ...props }) {
  const classes = useStyles()
  const childrenArray = React.Children.toArray(children)
  const [step, setStep] = React.useState(0)
  const currentChild = childrenArray[step]

  function isLastStep() {
    return step === childrenArray.length - 1
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers)
        } else {
          setStep((s) => s + 1)
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <MobileStepper
            variant="dots"
            steps={childrenArray.length}
            position="static"
            activeStep={step}
            className={classes.root}
          >
            {/* {childrenArray.map((child) => (
              <Step key={child.props.label}>
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))} */}
          </MobileStepper>
          {currentChild}

          <Box marginY={10}>
            <Grid container spacing={2} justify="center">
              {step > 0 && (
                <Grid item>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    onClick={() => setStep((s) => s - 1)}
                  >
                    Back
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  {isLastStep() ? 'Submit' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
