import DateFnsUtils from '@date-io/date-fns'
import { Box, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Field } from 'formik'
import { DatePicker } from 'formik-material-ui-pickers'
import React from 'react'
import { object, string } from 'yup'

export const deadlineActionPlaneSchema = object({
  deadlineActionPlan: string().required().default(new Date()),
})

export const DeadlineActionPlan = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box marginBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          A3. Batas waktu menyelesaikan Action Plan Sales
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop={5}
        >
          <Field
            component={DatePicker}
            label="Date"
            name="deadlineActionPlan"
            autoOk
            // orientation="landscape"
            variant="static"
            format="yyyy-MM-dd"
            // disableFuture
            disablePast
          />
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  )
}
