import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const ProductDisplaySchema = object({
  labelPricetag: string().required(),
  signedArea: string().required(),
  standardVm: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData('Display Sesuai standard VM', <RateRadio name="displayVm" />),
  createData(
    'Label, Pricetag ada di Produk',
    <RateRadio name="labelPricetag" />
  ),
  createData(
    'Penggunaan signed sesuai Area disc dan normal ?',
    <RateRadio name="signedArea" />
  ),
  createData(
    'Penambahan stock sesuai dengan standar VM',
    <RateRadio name="standardVm" />
  ),
]

export const ProductDisplay = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          B1. Product Display & VM
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
