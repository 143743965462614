import { FormControlLabel, Radio } from '@material-ui/core'
import { Field } from 'formik'
import { RadioGroup } from 'formik-material-ui'
import React from 'react'

export const RateRadio = ({ name }) => {
  return (
    <>
      <Field row component={RadioGroup} name={name}>
        <FormControlLabel value="puas" control={<Radio />} label="Puas" />
        <FormControlLabel value="baik" control={<Radio />} label="Baik" />
        <FormControlLabel value="cukup" control={<Radio />} label="Cukup" />
        <FormControlLabel value="buruk" control={<Radio />} label="Buruk" />
      </Field>
    </>
  )
}
