import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { RateRadio } from '../RateRadio'

export const Satisfied = () => {
  return (
    <>
      <Box marginBottom={2} marginTop={5}>
        <Typography variant="h5" align="center">
          Overall, how satisfied are you with the boutique ?
        </Typography>
        <Box
          marginTop={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <RateRadio name="overallReview" />
        </Box>
      </Box>
    </>
  )
}
