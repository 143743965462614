import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const DisiplinSchema = object({
  disiplinAbsen: string().required(),
  keadaanToko: string().required(),
  gembokKasir: string().required(),
  bacaEmail: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData(
    'Kedisiplinan absen dan sesuai jadwal?',
    <RateRadio name="disiplinAbsen" />
  ),
  createData(
    'Cek keadaan gembok, rolling door, window',
    <RateRadio name="keadaanToko" />
  ),
  createData(
    'Kunci gembok di simpan area kasir',
    <RateRadio name="gembokKasir" />
  ),
  createData('Email sudah di baca', <RateRadio name="bacaEmail" />),
]

export const Disiplin = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          C1. Disiplin
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
