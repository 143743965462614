import { Box } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'
import { object, string } from 'yup'

export const SpgSchema = object({
  teamBtq: string().min(4).required('This field is required'),
})

export const SpgTeam = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Field
          name="teamBtq"
          fullWidth
          component={TextField}
          label="SPG yang bertugas"
        />
      </Box>
    </>
  )
}
