import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const F1Schema = object({
  greetings: string().required(),
  kebutuhanCust: string().required(),
  keunggulanProduk: string().required(),
  promoHighlightProduk: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData('Greetings', <RateRadio name="greetings" />),
  createData('Menggali Kebutuhan Cust', <RateRadio name="kebutuhanCust" />),
  createData('Fitur Keunggulan Produk', <RateRadio name="keunggulanProduk" />),
  createData(
    'Informasi Promo dan highlight produk',
    <RateRadio name="promoHighlightProduk" />
  ),
]

export const F1 = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          F1. Team Butik Melakukan / Menjelaskan point dibawah pada customer
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
