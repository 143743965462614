import {
  AppBar,
  Box,
  Container,
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { Home } from './pages/Home'
import { Switch, Route } from 'react-router-dom'
import { Success } from './pages/Success'
import { Logo } from './components/Logo'

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#B1660D',
    },
  },
})

const App = () => {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <Typography variant="h6">Visit App</Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <Box marginTop={10}>
            <Logo />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/success" component={Success} />
            </Switch>
          </Box>
        </Container>
      </MuiThemeProvider>
    </div>
  )
}

export default App
