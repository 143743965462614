import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const SalesAndTargetBoutiqueSchema = object({
  actionTargetLastVisit: string().required(),
  salesWeeklyMonthly: string().required(),
  productKnowledge: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData(
    'Memenuhi action dan target dari visit terakhir ?',
    <RateRadio name="actionTargetLastVisit" />
  ),
  createData('Pencapaian Sales vs Target', <RateRadio name="salesVsTarget" />),
  createData(
    'Adakah Rencana butik untuk mencapai sales weekly/ Monthly',
    <RateRadio name="salesWeeklyMonthly" />
  ),
  createData(
    'Kualitas Produk Knowledge Staff ?',
    <RateRadio name="productKnowledge" />
  ),
]

export const SalesAndTargetBoutique = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          A1. Sales & Target boutique
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
