import { Box, Typography } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'
import { object, string } from 'yup'

export const CatatanSchema = object({
  catatan: string().required('This field is required'),
})

export const Catatan = () => {
  return (
    <>
      <Box marginTop={5}>
        <Typography variant="h6" align="center">
          Catatan secara menyeluruh
        </Typography>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <Field
          fullWidth
          name="catatan"
          component={TextField}
          label="Perlu tidaknya coaching ?"
          multiline
          InputProps={{ variant: 'filled' }}
        />
      </Box>
    </>
  )
}
