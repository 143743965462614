import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../api/api';

const useCreateVisit = () => {
  const history = useHistory();

  return useMutation(
    (values) => axios.post(`${BASE_URL}/visit`, values).then((res) => res.data),
    {
      onSuccess: (values) => {
        queryCache.invalidateQueries('visits');
        history.push('/success');
      },
      onError: (error) => {
        console.log({ error });
        alert(error.response.data.message);
      },
    }
  );
};

export default useCreateVisit;
