import { Box, Grid } from '@material-ui/core'
import './Logo.css'
import React from 'react'

export const Logo = () => {
  return (
    <Box>
      <Grid container spacing={1} justify="center">
        <Grid item>
          <img
            src="/logo/bonia.jpg"
            width="120"
            height="120"
            alt="bonia logo"
          />
        </Grid>
        <Grid item>
          <img
            src="/logo/bb.jpg"
            width="120"
            height="120"
            alt="braun buffel logo"
          />
        </Grid>
      </Grid>
    </Box>
  )
}
