import { Box } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'
import { object, string } from 'yup'

export const emailSchema = object({
  email: string()
    .email('Invalid email format')
    .required('This field is required'),
})

export const Email = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Field
          fullWidth
          name="email"
          type="email"
          component={TextField}
          label="Email"
        />
      </Box>
    </>
  )
}
