import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const F2Schema = object({
  upCrossSelling: string().required(),
  menawarkanMember: string().required(),
  perawatanProduk: string().required(),
  tncAfterSales: string().required(),
  lastGreetings: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData('Up / Cross Selling', <RateRadio name="upCrossSelling" />),
  createData(
    "Menawarkan D'guild Member / EBI",
    <RateRadio name="menawarkanMember" />
  ),
  createData('Cara Perawatan Produk', <RateRadio name="perawatanProduk" />),
  createData(
    'Term & Condition after sales service',
    <RateRadio name="tncAfterSales" />
  ),
  createData(
    'Ucapan terima kasih dan mengudang kembali cust untuk datang kembali.',
    <RateRadio name="lastGreetings" />
  ),
]

export const F2 = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          F1. Team Butik Melakukan / Menjelaskan point dibawah pada customer
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
