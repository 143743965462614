import Axios from 'axios';
import { useQuery } from 'react-query';
import { BASE_URL } from '../../api/api';

const useStores = () => {
  return useQuery('stores', () => {
    return Axios.get(`${BASE_URL}/api/v1/store`).then((res) => res.data);
  });
};

export default useStores;
