import { Box, InputLabel, MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { Select } from 'formik-material-ui'
import React from 'react'
import { object, string } from 'yup'
import useStores from '../../hooks/Store.js/useStores'

export const butikSchema = object({
  visitedBtq: string().required(),
})

export const ButikVisit = () => {
  const { data, isLoading } = useStores()

  if (isLoading) return <div>Loading...</div>
  return (
    <div>
      <Box paddingBottom={2} marginTop={5}>
        <InputLabel htmlFor="visitedBtq">Butik yang di visit</InputLabel>
        <Field
          fullWidth
          name="visitedBtq"
          component={Select}
          label="Butik yang di visit"
          inputProps={{
            id: 'visitedBtq',
          }}
        >
          {isLoading ? (
            <MenuItem>Loading...</MenuItem>
          ) : (
            data.map((s) => {
              return (
                <MenuItem value={s.name.toUpperCase()} key={s.id}>
                  {s.code.toUpperCase()} - {s.name.toUpperCase()}
                </MenuItem>
              )
            })
          )}
        </Field>
      </Box>
    </div>
  )
}
