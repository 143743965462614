import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { object, string } from 'yup'
import { RateRadio } from '../RateRadio'

export const D1Schema = object({
  lampu: string().required(),
  kebersihanDisplayLuarDalam: string().required(),
  kebersihanDisplayShowcase: string().required(),
  kebersihanFloorAndGudang: string().required(),
  kebersihanShowcase: string().required(),
})

function createData(name, radio) {
  return { name, radio }
}

const rows = [
  createData(
    'Lampu on Sesuai dan berdampak pada produk',
    <RateRadio name="lampu" />
  ),
  createData(
    'Kebersihan window display luar dalam',
    <RateRadio name="kebersihanDisplayLuarDalam" />
  ),
  createData(
    'Produk display dan showcase bebas debu dan kotor',
    <RateRadio name="kebersihanDisplayShowcase" />
  ),
  createData(
    'Kebersihan floor dan gudang ',
    <RateRadio name="kebersihanFloorAndGudang" />
  ),
  createData('Kebersihan showcase ', <RateRadio name="kebersihanShowcase" />),
]

export const D1 = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Typography variant="h6" align="center">
          D1. Kelengkapan dan kebersihan
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.radio}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
