import { Box } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'
import { object, string } from 'yup'

export const visitorSchema = object({
  firstName: string()
    .required('This field is required')
    .min(3, 'Name should be more than 3 characters'),
  lastName: string()
    .required('This field is required')
    .min(3, 'Name should be more than 3 characters'),
})

export const PersonalData = () => {
  return (
    <>
      <Box paddingBottom={2} marginTop={5}>
        <Field
          name="firstName"
          fullWidth
          component={TextField}
          label="First Name"
          inputRef={(input) => input && input.focus()}
        />
      </Box>
      <Box paddingBottom={2}>
        <Field
          name="lastName"
          fullWidth
          component={TextField}
          label="Last Name"
        />
      </Box>
    </>
  )
}
